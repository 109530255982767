import './App.css';
import XmasTree from "./page";

function App() {
  return (
    <div className="App">
      <XmasTree />
    </div>
  );
}

export default App;
