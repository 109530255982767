import React from "react";
import ToyBlue from '../../../images/toy_blue.png';
import ToyGreen from '../../../images/toy_green.png';
import ToyPink from '../../../images/toy_pink.png';
import ToyYellow from '../../../images/toy_yellow.png';
import ToyPurple from '../../../images/toy_purple.png';

const Toy = ({wish, id, modalHandler}) => {

    const toyRender = () => {
        switch (id) {
            case 1:
                return ToyBlue
            case 2:
                return ToyGreen
            case 3:
                return ToyPink
            case 4:
                return ToyYellow
            case 5:
                return ToyPurple
            case 6:
                return ToyYellow
            case 7:
                return ToyPink
            case 8:
                return ToyGreen
            case 9:
                return ToyPurple
            default:
                return ToyBlue
        }
    };

    const strCutter = (n, str) => {
        if (str.length <= n) {
            return str;
        } else {
            return str.slice(0, n) + "...";
        }
    };

    const sentenceEditor = (sent) => {
        const sentArr = sent.split(" ");
        if (sentArr.length > 4) {
            return [...sentArr.slice(0, 4), "..."]
        } else {
            return sentArr
        }
    }

    return (
        <div className={`toy__container toy__transform-${id}`}>
            <button className="toy__wrap" onClick={() => modalHandler(wish)}>
                <img src={toyRender()} className="toy__img" alt="toy"/>
                <div className="toy__name">
                    {sentenceEditor(wish.to).map((word, idx) => (
                        <span key={idx} style={{textOverflow: "ellipsis"}}>{strCutter(12, word)}</span>
                    ))}
                </div>
            </button>
        </div>
    )
}

export default Toy;