import React from 'react';
import CreateToyImg from '../../../images/create-toy.png';

const CreateToy = () => {

    return (
        <div className="toy__container toy__transform-2">
            <a className="create__link" href="#">
                <img src={CreateToyImg} alt="create" className="create__toy-img"/>
            </a>
        </div>
    )
}

export default CreateToy