import React, {useState} from "react";
import TreeMain from '../images/tree-main.jpg';
import TreeHead from '../images/tree-head.png';
import TreeMiddle from '../images/tree-middle.png';
import Logo from '../images/logo.png';
import {wishesMock} from "./mocks";
import Toy from "./components/toy";
import Modal from "./components/modal";
import CreateToy from "./components/createToy";

const XmasTree = () => {
    const [activeModal, setActiveModal] = useState();

    function* chunks(arr, n) {
        for (let i = 0; i < arr.length; i += n) {
            yield arr.slice(i, i + n);
        }
    }

    const newWishes = [...wishesMock]
    newWishes.splice(2, 0, {to: "create", wish: "create", create: true})

    const wishesSections = [
        ...chunks(wishesMock, 9)
    ];

    const modalHandler = (wish) => {
        setActiveModal(wish)
    }

    return (
        <div className="container">
            <img src={TreeMiddle} alt="tree-head" className="page__tree-head" />
            <img src={TreeHead} alt="tree-head" className="page__tree-head" />
            <div className="page__text-container">
                <img src={Logo} alt="tree-head" className="page__logo" />
                <h1 className="page__title">Digital елка 2024 <br/> Зажги с AQUAART</h1>
                <h3 className="page__counter">{`Уже написано благодарностей: ${wishesMock.length}`}</h3>
            </div>
            <img src={TreeHead} alt="tree-head" className="page__tree-main-img" />
            <img src={TreeMiddle} alt="tree-head" className="page__tree-main-img" />
            {wishesSections.map((section, idx) => (
                <div className="page__tree-main-container" key={idx}>
                    <img src={TreeMain} alt="tree-head" className="page__tree-main-img" />
                    <div className="page__toys-container">
                        <div className="page__toys-wrap-first">
                            {section.slice(0, 5).map((toy, idx) => (
                                !!toy.create ? (
                                    <CreateToy key={idx}/>
                                ) : (
                                    <Toy wish={toy} key={idx} id={idx + 1} modalHandler={modalHandler}/>
                                )
                            ))}
                        </div>
                        <div className="page__toys-wrap-second">
                            {section.slice(5).map((toy, idx) => (
                                <Toy wish={toy} key={idx} id={idx + 6} modalHandler={modalHandler}/>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
            {activeModal && <Modal wish={activeModal} onClose={() => setActiveModal(null)}/>}
        </div>
    )
}

export default XmasTree;