import React, {useEffect} from 'react';
import IconClose from '../../../images/icon-close.svg'

const Modal = ({wish, onClose}) => {

    useEffect(() => {
        const close = (e) => {
            if(e.keyCode === 27){
                onClose()
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    },[onClose])

    useEffect(() => {
        document.body.style.overflow = "hidden";

        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    return (
        <div className="modal__overlay" onClick={onClose}>
            <div className="modal__wrap" onClick={(e) =>e.stopPropagation()}>
                <div className="modal__btn-wrapper">
                    <button className="modal__close-btn" onClick={onClose}>
                        <img className="modal__close-icon" src={IconClose} alt="close-icon"/>
                    </button>
                </div>
                <div className="modal__info">
                    <div className="modal__info-wish-wrap">
                        <span className="modal__info-wish-title">Кому:</span>
                        <span className="modal__info-to">{wish.to}</span>
                    </div>
                    <div className="modal__info-wish-wrap">
                        <span className="modal__info-wish-title">Благодарность:</span>
                        <span className="modal__info-wish">{wish.wish}</span>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Modal